export const environment = {
  production: true,
  GRAPHQL_URL: 'https://ao32wy6ehe.execute-api.eu-west-1.amazonaws.com/staging/portal/graphql',
  GRAPHQL_PROTECTED_URL: 'https://ao32wy6ehe.execute-api.eu-west-1.amazonaws.com/staging/a-portal/graphql',
  GRAPHQL_SCHEMA_URL: 'https://ao32wy6ehe.execute-api.eu-west-1.amazonaws.com/staging/portal/schema',
  BUILD_QUERY_KEY: '6a004d54ad43#14',
  STRIPE_PUBLIC_KEY: 'pk_test_51Qi5EFCH65qHeuOepw78FZt7hZsnhFMqaUIYx6DxSoIYHdBFXu2Bu1NLaJuYbGRFqoG5OBn99Yd4IdoWIsQpFUHR00SJreogAG',
  CLOUDFRONT_API: 'https://d3oa8725zk6wjh.cloudfront.net/staging/portal/graphql',
  TRACKING_URL: 'https://staging-tr.businessclassconsolidator.com',
  AWS_PROJECT_REGION: 'eu-west-1',
  AWS_COGNITO_REGION: 'eu-west-1',
  AWS_USER_POOLS_ID: 'eu-west-1_7vq5burya',
  AWS_USER_POOLS_WEB_CLIENT_ID: '5qkbq33e6o7v0u9q1ocdlubv3a',
  OAUTH_DOMAIN: 'staging-portal.auth.eu-west-1.amazoncognito.com',
  AMPLIFY_REDIRECT_SIGN_IN: 'https://staging-q.bcconsolidator.com/login',
  AMPLIFY_REDIRECT_SIGN_OUT: 'https://staging-q.bcconsolidator.com/logout',
};